var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _vm.restaurantName
            ? _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-text" } }),
                      _c("h5", { staticClass: "d-inline ml-2" }, [
                        _c(
                          "h3",
                          { staticClass: "d-inline" },
                          [
                            _c("CBadge", { attrs: { color: "secondary" } }, [
                              _vm._v(
                                _vm._s(_vm._f("uppercase")(this.restaurantName))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" Linked Types "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-header-actions" },
                        [
                          _vm.restaurantId
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    color: "primary",
                                    to: {
                                      name: "View Restaurant",
                                      params: { id: _vm.restaurantId },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    staticClass: "align-bottom",
                                    attrs: { name: "cil-arrow-thick-left" },
                                  }),
                                  _vm._v(" "),
                                  _c("strong", [_vm._v("Back")]),
                                  _vm._v(" To Restaurant "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c("LinkedTypes", {
                        attrs: {
                          restaurantId: _vm.restaurantId,
                          linkedTypes: _vm.linkedTypes,
                        },
                        on: {
                          "update:linkedTypes": function ($event) {
                            _vm.linkedTypes = $event
                          },
                          "update:linked-types": function ($event) {
                            _vm.linkedTypes = $event
                          },
                        },
                      }),
                      _c(
                        "CCard",
                        { staticClass: "actions sticky-bottom" },
                        [
                          _c("CCardBody", { staticClass: "p-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap align-items-center",
                                staticStyle: { gap: "0.75rem" },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled:
                                            _vm.loading || _vm.submitted,
                                        },
                                        on: { click: _vm.storeLinkedTypes },
                                      },
                                      [
                                        _vm.submitted
                                          ? _c("CSpinner", {
                                              attrs: { size: "sm" },
                                            })
                                          : _c("CIcon", {
                                              attrs: { name: "cil-send" },
                                            }),
                                        _c("strong", [_vm._v(" Submit")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "ml-auto" }, [
                                  _c("h5", { staticClass: "mt-1" }, [
                                    _vm._v("Total: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-inline count bg-primary pb-1",
                                      },
                                      [_vm._v(_vm._s(_vm.totalLinkedTypes))]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.loading
                    ? _c("CSpinner", { attrs: { color: "primary" } })
                    : _c("h6", [_vm._v("Restaurant not found!")]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }